// eslint-disable-next-line no-restricted-imports
import { Alert as CommonAlert } from '@agtuary/ui/alerts';
import { ComponentProps, useCallback } from 'react';
import { useDismissibleAlertStore } from 'stores/DismissibleAlertStore';

type AlertProps = Omit<ComponentProps<typeof CommonAlert>, 'title'> & {
  title: string;
};

export function Alert(props: AlertProps) {
  const { onClose: propsOnClose, title, isDismissible } = props;

  const { setAlertDismissed, dismissedAlerts, _hasHydrated } =
    useDismissibleAlertStore();

  const onClose = useCallback(() => {
    if (propsOnClose) {
      propsOnClose();
    }

    if (isDismissible) {
      setAlertDismissed(title);
    }
  }, [isDismissible, propsOnClose, setAlertDismissed, title]);

  // Suppress dismissable alerts until the store has been hydrated, to prevent flickering
  if (!_hasHydrated || dismissedAlerts[title]) {
    return null;
  }

  return <CommonAlert {...props} onClose={onClose} />;
}
