import { useUser } from '@agtuary/api/hooks/users/useUser';
import { useUserTeams } from '@agtuary/api/hooks/users/useUserTeams';
import { Team } from '@agtuary/api/types/team';
import { setUser as setSentryUser } from '@sentry/core';
import { signIn, useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ALLOWED_SIGNUP_PARAMS } from 'helpers/routes/query-string';
import { useCurrentTeam } from './useCurrentTeam';

// This custom hook returns the currently authenticated user and their associated teams
export const useCurrentUser = (): {
  user: ReturnType<typeof useUser>; // The currently authenticated user
  userId: string | undefined; // The ID of the currently authenticated user
  teams: ReturnType<typeof useUserTeams>; // The teams associated with the current user
  currentTeam: Team | undefined; // The current team for the user
  setCurrentTeam: (team: Team) => void; // A function to set the current team for the user
} => {
  const session = useSession();

  const router = useRouter();
  const { query } = router;

  // If the user is not authenticated, redirect them to the login page
  useEffect(() => {
    if (
      session?.status !== 'loading' &&
      (!session?.data?.user?.id || session?.status === 'unauthenticated')
    ) {
      // Create a new object with all query params except 'email'
      const { email, ...filteredQuery } = query;

      // Convert query params to URL search string
      const params = new URLSearchParams();
      Object.entries(filteredQuery).forEach(([key, value]) => {
        if (!ALLOWED_SIGNUP_PARAMS.includes(key)) {
          return;
        }

        if (Array.isArray(value)) {
          value.forEach((val) => params.append(key, val));
        } else if (value !== undefined) {
          params.append(key, String(value));
        }
      });

      const queryString = params.toString();

      // Construct the callback URL with filtered query params
      const callbackUrl = queryString ? `/?${queryString}` : '/';

      signIn('auth0', {
        ...(email && email.length > 0 ? { login_hint: email } : {}),
        callbackUrl,
      });
    }
  }, [session, query]);

  // Get the current user's ID from the session
  const userId = session?.data?.user?.id;

  // Get the user's data from the API
  const user = useUser(userId);

  // Get the teams associated with the current user
  const teams = useUserTeams(userId);

  // Get the current team for the user
  const { currentTeam, setCurrentTeam } = useCurrentTeam(
    teams?.data,
    user?.data,
  );

  useEffect(() => {
    if (userId) {
      setSentryUser({
        id: userId,
        username:
          [user?.data?.firstName, user?.data?.lastName].join(' ') || undefined,
      });
    }
  }, [userId, user?.data?.firstName, user?.data?.lastName]);

  return {
    user,
    userId,
    teams,
    currentTeam,
    setCurrentTeam,
  };
};
