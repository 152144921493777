import { routeId } from '@agtuary/common/helpers/encode/base62';
import { HelpIcon } from '@agtuary/ui/icons';
import { Avatar } from '@mantine/core';
import { useAccessRights } from 'hooks/useAccessRights';
import { useApplicationMode } from 'hooks/useApplicationMode';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useRouter } from 'next/router';
import { ComponentProps, useMemo } from 'react';
import { useAnalyticsEvents } from 'hooks/useAnalyticsEvents';
import { HeaderMenu } from './HeaderMenu';

export function SecondaryHeaderMenu() {
  const {
    currentTeam,
    user: { data: user },
  } = useCurrentUser();
  const { hasAdvisor } = useAccessRights();
  const router = useRouter();

  const firstName = user?.firstName || '';
  const lastName = user?.lastName || '';
  const fullName = `${firstName} ${lastName}`.trim();
  const userInitials = `${firstName.charAt(0) || ''}${
    lastName.charAt(0) || ''
  }`;

  const {
    options: { helpUrl },
  } = useApplicationMode();

  const { sendEvent } = useAnalyticsEvents();

  const withinTeam = Boolean(router.query.teamId);
  const orgName = currentTeam?.organisation?.name;
  const orgNameToDisplay = orgName && orgName !== '' ? `${orgName}: ` : '';

  const buttons: ComponentProps<typeof HeaderMenu>['buttons'] = useMemo(
    () => ({
      account: {
        label: 'Account',
        hasAdvisor,
        subLabel: withinTeam ? orgNameToDisplay : undefined,
        subLabelDetail: withinTeam ? currentTeam?.name : undefined,
        route: {
          pathname: '/my/profile',
          query: {
            teamId: routeId(currentTeam?.id),
          },
        },
        isActive: router.route.startsWith('/my/profile'),
        buttonProps: {
          rightIcon: (
            <Avatar
              radius="xl"
              size="1.5rem"
              alt={fullName}
              color="violet"
              variant="filled"
            >
              {userInitials}
            </Avatar>
          ),
        },
      },
      ...(helpUrl && {
        help: {
          label: 'Help',
          externalLink: helpUrl,
          isActive: false,
          hasAdvisor: false,
          buttonProps: {
            rightIcon: <HelpIcon />,
            target: '_blank',
            onClick: () => {
              sendEvent('misc-help-opened');
            },
          },
        },
      }),
    }),
    [
      currentTeam?.id,
      currentTeam?.name,
      fullName,
      hasAdvisor,
      helpUrl,
      orgNameToDisplay,
      router.route,
      userInitials,
      withinTeam,
      sendEvent,
    ],
  );

  return <HeaderMenu buttons={buttons} />;
}
