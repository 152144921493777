import { Alert as MTAlert, AlertProps as MTAlertProps } from '@mantine/core';
import { useCallback, useMemo, useState } from 'react';
import {
  AlertStopIcon,
  CircleCheckIcon,
  CircleInfoIcon,
  DangerIcon,
} from '../icons';
import styles from './Alert.module.scss';

type AlertProps = {
  isDismissible: boolean;
  type: 'info' | 'error' | 'warning' | 'success';
} & MTAlertProps;

export function Alert({
  isDismissible,
  type,
  onClose: propsOnClose,
  ...props
}: AlertProps) {
  const [showAlert, setShowAlert] = useState(true);

  const Icon = useMemo(() => {
    switch (type) {
      case 'info':
        return CircleInfoIcon;
      case 'error':
        return AlertStopIcon;
      case 'warning':
        return DangerIcon;
      case 'success':
        return CircleCheckIcon;
      default:
        return CircleInfoIcon;
    }
  }, [type]);

  const onClose = useCallback(() => {
    setShowAlert(false);

    if (propsOnClose) {
      propsOnClose();
    }
  }, [propsOnClose]);

  const classNames = useMemo(
    () => ({
      root: styles.alert,
      title: styles.alertTitle,
      icon: styles.alertIcon,
      message: styles.alertMessage,
    }),
    [],
  );

  const icon = useMemo(() => <Icon width={32} height={32} />, [Icon]);

  if (!showAlert) {
    return null;
  }

  return (
    <MTAlert
      classNames={classNames}
      {...props}
      data-type={type}
      withCloseButton={isDismissible}
      closeButtonLabel={type}
      onClose={onClose}
      icon={icon}
    />
  );
}
