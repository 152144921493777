import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// _hasHydrated etc taken from https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md#how-can-i-check-if-my-store-has-been-hydrated
type DismissibleAlertStoreState = {
  dismissedAlerts: Record<string, boolean>;
  _hasHydrated: boolean;
};

type DismissibleAlertStore = DismissibleAlertStoreState & {
  setAlertDismissed: (alertId: string) => void;
  setHasHydrated: (state: boolean) => void;
};

const initialState: DismissibleAlertStoreState = {
  dismissedAlerts: {},
  _hasHydrated: false,
};

export const useDismissibleAlertStore = create(
  persist<DismissibleAlertStore>(
    (set) => ({
      ...initialState,
      setAlertDismissed: (alertId: string) =>
        set((state) => ({
          ...state,
          dismissedAlerts: {
            ...state.dismissedAlerts,
            [alertId]: true,
          },
        })),
      _hasHydrated: false,
      setHasHydrated: (state: boolean) => {
        set({
          _hasHydrated: state,
        });
      },
    }),
    {
      name: 'dismissed-alerts',
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    },
  ),
);

// Helper functions to access outside of React components
export const resetDismissibleAlertStore = () => {
  useDismissibleAlertStore.persist.clearStorage();
};
