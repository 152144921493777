import { PopOutIcon } from '@agtuary/ui/icons';
import {
  Title,
  Text,
  Space,
  Center,
  Flex,
  Button,
  Anchor,
} from '@mantine/core';
import { Alert } from 'components/Alert';
import { useAnalyticsEvents } from 'hooks/useAnalyticsEvents';
import { useCurrentTeamSubscription } from 'hooks/useCurrentTeamSubscription';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEffect, useMemo, useState } from 'react';

const popOutIcon = <PopOutIcon width={10} height={10} />;
const stripeUrl = process.env.NEXT_PUBLIC_STRIPE_BILLING_MANAGE_URL;

function ManageSubscriptionButton() {
  return (
    <Button
      variant="outline"
      rightIcon={popOutIcon}
      onClick={() => {
        window.open(stripeUrl, '_blank');
      }}
    >
      Manage Subscription
    </Button>
  );
}

function ManageSubscription() {
  return (
    <Center>
      <Flex direction="column" align="center" gap="sm">
        <Space h="lg" />
        <Title order={5}>Manage your Subscription</Title>
        <Text size="sm">
          Modify your subscription or contact us at{' '}
          <Anchor
            variant="text"
            href="mailto:contact@agtuary.com"
            color="brand.7"
            target="_blank"
          >
            contact@agtuary.com
          </Anchor>{' '}
          to change plans.
        </Text>
        <ManageSubscriptionButton />
        <Space h="xl" />
      </Flex>
    </Center>
  );
}

function Canceled() {
  return (
    <Center>
      <Flex direction="column" align="center" gap="sm">
        <Space h="lg" />
        <Text size="sm">
          Your subscription is currently canceled. Renew your subscription or
          contact us at{' '}
          <Anchor
            variant="text"
            href="mailto:contact@agtuary.com"
            color="brand.7"
            target="_blank"
          >
            contact@agtuary.com
          </Anchor>{' '}
          for help.
        </Text>
        <ManageSubscriptionButton />
        <Space h="xl" />
      </Flex>
    </Center>
  );
}

const noSubscription = (currentTeamId: string, userEmail: string) => (
  <>
    {process.env.NEXT_PUBLIC_STRIPE_FREE_TRIAL_DAYS ? (
      <Alert
        mt={16}
        title={`Subscribe and get a ${process.env.NEXT_PUBLIC_STRIPE_FREE_TRIAL_DAYS} day free trial!`}
        isDismissible
        type="success"
      >
        Reports are free during the trial period.
      </Alert>
    ) : undefined}
    <Title order={5}>
      Select a plan to start making the most of the Agtuary platform!
    </Title>
    <Text>
      Our report pricing is{' '}
      <Text component="span" fw={700} c="brand">
        ${process.env.NEXT_PUBLIC_NORMAL_REPORT_PRICE}
      </Text>{' '}
      while on a free subscription, or{' '}
      <Text component="span" fw={700} c="brand">
        ${process.env.NEXT_PUBLIC_REDUCED_REPORT_PRICE}
      </Text>{' '}
      while on a paid team subscription.
    </Text>
    <Text italic>Prices are GST inclusive.</Text>
    <Space h="xl" />
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore - stripe-pricing-table is not typed */}
    <stripe-pricing-table
      pricing-table-id={process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID}
      publishable-key={process.env.NEXT_PUBLIC_STRIPE_PRICING_PUBLISHABLE_ID}
      client-reference-id={currentTeamId}
      customer-email={userEmail}
    />
    <Space h="md" />
  </>
);

function noTeamId() {
  return (
    <Text>
      There has been an error loading subscription plans. Please refresh,
      re-login or contact us at{' '}
      <Anchor
        variant="text"
        href="mailto:contact@agtuary.com"
        color="brand.7"
        target="_blank"
      >
        contact@agtuary.com
      </Anchor>
    </Text>
  );
}

export function PaymentModal() {
  const { currentTeam, user } = useCurrentUser();
  const {
    isSubscribed: isCurrentTeamSubscribed,
    isCanceled: isCurrentTeamCanceled,
  } = useCurrentTeamSubscription();

  const [openedWithNoSub, setOpenedWithNoSub] = useState(false);
  const { sendEvent } = useAnalyticsEvents();

  useEffect(() => {
    if (openedWithNoSub) {
      sendEvent('team-payment-opened');
    }
  }, [openedWithNoSub, sendEvent]);

  const ModalContent = useMemo(() => {
    // appears on pages/features users dont have a subscription for, but they do have a
    // subscription
    if (isCurrentTeamSubscribed) {
      return ManageSubscription();
    }

    if (isCurrentTeamCanceled) {
      return Canceled();
    }

    if (!currentTeam || !currentTeam?.id) {
      return noTeamId();
    }

    setOpenedWithNoSub(true);

    return noSubscription(currentTeam?.id, user?.data.email);
  }, [
    currentTeam,
    isCurrentTeamSubscribed,
    isCurrentTeamCanceled,
    user?.data.email,
  ]);

  return ModalContent;
}
