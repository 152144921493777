import { createContext } from 'react';
import { Query } from 'nextjs-routes';
import { FeatureFlags } from 'feature-flags';
import type { RootLayoutProps } from '../RootLayout';
import { NavItemGroup } from '../components/Nav/types';

export type RootLayoutContextType = {
  headerContentBoxId: string | undefined;
  navAfterItemsSectionId: string | undefined;
  layoutProps: RootLayoutProps | undefined;
  initialLayoutProps: RootLayoutProps | undefined;
  setLayoutProps: (props: Partial<RootLayoutProps>) => void;
  resetLayoutProps: () => void;
  setNavCollapsed: (collapsed: boolean) => void;
  setNavItems: (
    nav: (query: Query, flags: FeatureFlags) => NavItemGroup[],
  ) => void;
};

export const defaultRootLayoutContext: RootLayoutContextType = {
  headerContentBoxId: undefined,
  navAfterItemsSectionId: undefined,
  layoutProps: undefined,
  initialLayoutProps: undefined,
  setLayoutProps: () => undefined,
  resetLayoutProps: () => undefined,
  setNavCollapsed: () => undefined,
  setNavItems: () => undefined,
};

export const RootLayoutContext = createContext(defaultRootLayoutContext);
